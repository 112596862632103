import "components/header"


//
// Particles
//
(() => {
  var particleCount = 60,
    proximity = 100,
    particles = [],
    canvas,
    context;

  // fit canvas to window
  var resizeCanvas = () => {
    var parent = canvas.parentNode;
    canvas.width = parent.clientWidth;
    canvas.height = parent.clientHeight;
  }

  // returns a (sort of) random color
  var getColor = () => {
    var random = Math.random(),
      color = '#333';

    if (random < 1) {
      color = '#fed600';
    }
    if (random < 0.85) {
      color = '#a0a8d5';
    }
    if (random < 0.75) {
      color = '#8ec73f';
    }
    if (random < 0.65) {
      color = '#f06392';
    }
    if (random < 0.55) {
      color = '#fbaa19';
    }
    if (random < 0.45) {
      color = '#3d82c4';
    }
    if (random < 0.35) {
      color = '#71cddd';
    }
    if (random < 0.25) {
      color = '#d41f26';
    }
    if (random < 0.15) {
      color = '#5cbc6b';
    }

    return color;
  }

  // create array of particles
  var createParticles = () => {
    for (var i = 0; i < particleCount; i++) {
      particles.push({
        x: Math.random() * canvas.width,
        y: Math.random() * canvas.height,
        velocityX: Math.random() * 4 - 1,
        velocityY: Math.random() * 4 - 1,
        history: [],
        color: getColor(),
        size: 4 + (Math.random() * 4)
      });
    }
  }

  // draw the frame
  var animateParticles = () => {
    context.clearRect(0, 0, canvas.width, canvas.height);

    for (var i = 0; i < particles.length; i++) {

      // update particle position by adding velocity value to x and y
      particles[i].x += particles[i].velocityX;
      particles[i].y += particles[i].velocityY;

      // push to history
      particles[i].history.push({
        x: particles[i].x,
        y: particles[i].y
      });

      // limit history length
      if (particles[i].history.length > 65) {
        particles[i].history.shift(); // remove first item from array
      }

      // update the velocity depending on the particles postion
      if (particles[i].x > canvas.width) {
        // go left
        particles[i].velocityX = -(Math.random() * 4 - 1);
      } else if (particles[i].x < 0) {
        particles[i].velocityX = Math.random() * 4 - 1;
      }

      if (particles[i].y > canvas.height) {
        // go up
        particles[i].velocityY = -(Math.random() * 4 - 1);
      } else if (particles[i].y < 0) {
        particles[i].velocityY = Math.random() * 4 - 1;
      }

      // set colors
      context.fillStyle = particles[i].color;
      context.strokeStyle = particles[i].color;

      // fill circle
      context.beginPath();
      context.arc(particles[i].x, particles[i].y, particles[i].size, 0, Math.PI * 2, true);
      context.fill();

      // draw tail
      context.beginPath();
      for (var j = 0; j < particles[i].history.length; j++) {
        context.lineTo(particles[i].history[j].x, particles[i].history[j].y);
      }
      context.stroke();
    }
  }

  // initialize
  var init = () => {
    canvas = document.getElementById('canvas-animation');
    context = canvas.getContext('2d');
    resizeCanvas();

    if (window.innerWidth < 768) {
      particleCount = 20;
      proximity = 50;
    }

    createParticles();

    window.setInterval(function() {
      animateParticles();
    }, 40);

    window.addEventListener('resize', function() {
      console.log('rezize');
      resizeCanvas();
    });
    window.addEventListener('orientationchange', function() {
      console.log('orientationchange');
      resizeCanvas();
    });
  }

  window.addEventListener('load', function() {
    init();
  });
})();
