var timer,
    header = document.getElementById('main-header'),
    navigation = document.getElementById('homepage-nav'),
    navExpand = header.querySelector('.nav-expand');

window.addEventListener('scroll', () => {
  if (window.scrollY < 200) {
    header.classList.add('start-transparent');
  } else {
    header.classList.remove('start-transparent');
  }
});

document.addEventListener('click', e => {
  e.stopPropagation();
  if (e.target !== navigation) {
    document.body.classList.remove('nav-expanded');
    navigation.classList.remove('expanded');
  }
});

navExpand.addEventListener('click', e => {
  e.preventDefault();
  e.stopPropagation();
  navigation.classList.toggle('expanded');
  document.body.classList.toggle('nav-expanded');
});
